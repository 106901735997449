import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>My Resume</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row gh-5">

				<div className="col-lg-6"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Experiences (2015 - now)</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2021 - now</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Senior Software Engineer</span>
                                	<span className="d_company">ARHS Group / EIB - European Investiment Bank</span>
                                	As an external consultant for ARHS Group, I have been engaged by the European Investment Bank (EIB) to work 
                                    at their headquarters in Luxembourg, a strategic location in the heart of Europe. 
                                    <br/>
                                    In this role, I am responsible for developing and implementing internal systems for the bank. 
                                    <br/>
                                    My technical expertise includes proficiency in Java, 
                                    JackartaEE, CDI framework, JSF, JPA, Oracle, Sybase, Jenkins, Github, Sonar and other tools.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2020 - 2021</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Entrepreneur/Owner and Consultant</span>
                                	<span className="d_company">PCI IT - Partnership, Consulting & Innovation</span>
                                	In 2020, I founded my own software development and consulting company in Brazil. 
                                    <br/>
                                    As a leader of this enterprise, I have successfully delivered a variety of software solutions for clients using 
                                    a range of cutting-edge technologies, including Java, Spring Framework (Spring Boot, MVC, JPA), JUnit, Maven, Git, 
                                    Docker, Oracle, MySQL and Postgres Database, Angular and NodeJS. 
                                    <br/>
                                    With my broad technical expertise and entrepreneurial spirit, I am well-positioned to help organizations achieve their digital 
                                    transformation goals.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2015 - 2021</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Senior Software Engineer / Salles Engineer / Tech Team Lead</span>
                                	<span className="d_company">Alta Rail Technology</span>
                                    I began my tenure at this company as a senior software engineer and sales support, where I was responsible for designing customized solutions 
                                    for the railway industry and providing technical sales support to customers. 
                                    <br/>
                                    After my dedication to these roles, I was offered the opportunity to take on a leadership position within the customer support team. 
                                    <br/>
                                    In this role, I was responsible for implementing and supporting our company's solutions to customers worldwide. 
                                    <br/>
                                    My experience in both technical and leadership roles has enabled me to excel in this position and deliver outstanding results for our clients.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6" 
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Experiences (1999 - 2015)</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2008 - 2015</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Full-stack Software Engineer - Tech Team Lead</span>
                                	<span className="d_company">Accenture</span>
                                    I joined this multinational company as a full-stack software engineer, with a focus on the railway sector. 
                                    <br/>
                                    In this position, I leveraged my expertise in a wide range of technologies, including Java, C++, Spring, Struts, Hibernate, JSF, 
                                    Oracle Database (SQL and PL\SQL ETL Routines), .Net Developer (C#, ASP.net, MVC, nHibernate), and ASP, among others, to develop 
                                    cutting-edge systems for our clients. 
                                    <br/>
                                    Through my involvement in several large-scale projects, I had the opportunity to grow my career, 
                                    eventually reaching the position of technical leader of a team of 50 individuals. 
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2004 - 2008</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Software Engineer</span>
                                	<span className="d_company">Engesis Engenharia e Sistemas</span>
                                    As an experienced software developer, I developed critical mission systems for the railway industry, including OCC, yard operations, 
                                    and full railway logistics process operations. 
                                    <br/>
                                    I have been involved in several projects for Vale group, 
                                    including the CCP - Centralized YardControl and UNILOG - Unification of Railway Processes.
                                    <br/>
                                    My expertise includes: Java and C++ programming, Software Architecture Projects,  
                                    SQL, PL/SQL, ASP, Oracle, Java/J2EE WebServices, C++ interfaces, Database routines, 
                                    JDeveloper and BC4J, Systems Integration (SOAP - WebServices)
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2000 - 2004</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Software Engineer</span>
                                	<span className="d_company">AS - Auditoria e Sistemas</span>
                                    This was the beginning of my career as an IT professional, where I served as an analyst and developer of systems, providing training, user support, and implementing systems. 
                                    <br/>
                                    My technical expertise includes:
                                    Object-Oriented Development using Delphi, ASP and PHP development, Integration with government control systems such as SISCOMEX.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="spacer-double"></div>
			</div>
		</div>
	);
}

export default hero;