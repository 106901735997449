import React from 'react';
import Typed from "react-typed";
import AOS from 'aos';

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true
});

const hero = () => {
    return(

        
        <div className="v-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="color"
                            data-aos="fade-up"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >
                                <span className="h1">Hello, I Am </span>Fabricio Entringer
                        </h2>
                        <div className="spacer-20"></div>
                        <div className="h1_big"
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >
                            <Typed
                                strings={["SOFTWARE ENGINEER ", "DEVELOPER" , "CONSULTANT"]}
                                typeSpeed={100}
                                backSpeed={40}
                                loop
                              />
                        </div>
                        <ul className="list_location"
                            data-aos="fade-up"
                            data-aos-delay="600"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true">
                            <li><span>DEVELOPMENT</span>Customized solutions</li>
                            <li><span>CONSULTING</span>Technology consulting</li>
                            <li><span>PARTNERSHIP</span>Outsourcing and support</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default hero;